@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "animate.css";

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }
}

#home-animate-text {
  animation: fadeIn 2s 0.1s backwards;
}

#home-animate-img {
  animation: fadeIn 2s 0.1s backwards;
}

@media screen and (max-width: 450px) {
  #l-home-img {
    width: 220px;
  }
  #l-home {
    padding-top: px;
  }
  #l-about-p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  #l-contact {
    padding-bottom: 40px;
    height: auto;
  }
  #l-portfolio {
    height: auto;
  }

}

@media only screen and (max-height: 550px) and (orientation: landscape) {
  #l-nav {
    height: auto;
  }
  #l-home-contact {
    font-size: 0.9rem;
    line-height: 1.5rem;
    padding: 10px 14px;
  }
  #l-home {
    height: auto;
    padding-top: 0;
  }
  #l-home-h1 {
    font-size: 2.5rem;
    margin-top: 55px;
  }
  #l-home-img {
    width: 220px;
    margin-top: 40px;
  }
  #l-about {
    height: auto;
  }
  #l-portfolio {
    height: auto;
  }
  #l-skills {
    height: auto;
  }
  #l-contact {
    height: auto;
  }
}